<template>
  <main class="px-5">
    <trac-loading v-if="isLoading" />
    <trac-validation-observer slim v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(addVariant)">
        <span class="font-bold text-2xl"> Add Variant</span>
        <div class="mt-8">
          <trac-validation-provider
            name="variant name"
            rules="required"
            v-slot="{ errors }"
          >
            <trac-input
              class="md:w-6/12"
              v-model.trim="product_variant.title"
              placeholder="Variant Name"
            ></trac-input>
            <trac-input-error v-if="errors[0]">{{
              errors[0]
            }}</trac-input-error>
          </trac-validation-provider>
          <trac-textarea
            class="mt-8"
            v-model.trim="product_variant.description"
            placeholder="Description"
          ></trac-textarea>
          <div class="grid md:flex flex-row md:justify-between gap-5 mt-8">
            <div class="flex-column md:w-6/12">
              <trac-input
                v-model.trim.number="product_variant.cost_price"
                placeholder="Enter Cost Price"
              ></trac-input>
            </div>

            <div class="flex-column md:w-6/12">
              <trac-validation-provider
                name="product price"
                rules="required"
                v-slot="{ errors }"
              >
                <trac-input
                  v-model.trim.number="product_variant.price"
                  placeholder="Enter Selling Price"
                ></trac-input>
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>
            </div>
          </div>
          <div class="griid md:flex flex-row justify-between gap-5 mt-8">
            <div class="flex-column md:w-6/12">
              <trac-input
                v-model.trim="product_variant.barcode"
                placeholder="Enter Barcode / SKU"
              ></trac-input>
            </div>
            <div class="flex-column md:w-6/12 mt-4 md:mt-0">
              <trac-button
                @button-clicked="generateBarcode"
                class="uppercase text-xs w-full"
                variant="outline"
                >Generate Barcode/SKU</trac-button
              >
            </div>
          </div>
          <!-- <div class="flex flex-row mt-5 justify-end border-b pb-8">
            <trac-button
              @button-clicked="generateBarcode"
              class="uppercase"
              variant="outline"
              >Generate Barcode/SKU</trac-button
            >
          </div> -->
          <div class="mt-8  font-semibold text-sm mb-6">
            <trac-toggle
              @newval="updateComboValue"
              :active="product_variant.combo"
              v-model="product_variant.combo"
              >This variant is a combo item</trac-toggle
            >

            <trac-modal
              v-if="comboModal"
              class="max-w-2xl w-full"
              @close="comboModal = false"
            >
              <AddCombo
                :products="sortedProducts"
                @add-combo="comboAdded($event)"
                class="z-20"
              ></AddCombo>
            </trac-modal>
          </div>

          <div class="" v-if="product_variant.combo">
            <div
              v-for="(combo, i) in product_variant.combo_items"
              :key="i"
              class="mt-3 flex flex-row gap-4 items-center"
            >
              <div
                class="flex flex-row bg-accentLight px-6 w-11/12 justify-between py-3 font-semibold text-xs"
              >
                <span>{{ combo.title }}</span>
                <span class="">{{ combo.quantity || combo.stock }}</span>
              </div>
              <div>
                <img
                  class="cursor-pointer"
                  src="../../assets/svg/close_btn.svg"
                  alt=""
                  @click="removeCombo(combo)"
                />
              </div>
            </div>
            <div class="mt-5 border-b pb-8">
              <trac-button variant="small" @button-clicked="openComboModal"
                >Add another item</trac-button
              >
            </div>
          </div>
          <div class="mt-5 flex flex-row w-6/12 gap-5 ml-auto mr-10">
            <trac-button class="uppercase" @button-clicked="$emit('close')"
              >Cancel</trac-button
            >
            <trac-button class="uppercase" buttonType="submit"
              >Confirm</trac-button
            >
          </div>
        </div>
      </form>
    </trac-validation-observer>
  </main>
</template>

<script>
import AddCombo from "./AddCombo";
import { eventBus } from "./../../main";

export default {
  name: "AddVariation",
  components: { AddCombo },
  props: {
    stores: {
      type: Array,
    },
    allCreatedproducts: {
      type: Array,
    },
    variantToEdit: {
      default: null,
    },
  },
  created() {
    if (this.variantToEdit) {
      this.product_variant = this.variantToEdit;
    }
  },
  computed: {
    sortedProducts() {
      return this.allCreatedproducts.sort((a, b) =>
        a.title > b.title ? 1 : -1
      );
    },
  },
  data() {
    return {
      isLoading: false,
      product_variant: {
        title: "",
        cost_price: "",
        price: "",
        barcode: "",
        description: "",
        combo: false,
        combo_items: [],
        store_stock: this.stores.map((store) => {
          return {
            re_order: 0,
            stock: 0,
            store_id: store._id,
            store_name: store.name,
          };
        }),
      },
      comboModal: false,
    };
  },
  methods: {
    updateComboValue(value) {
      this.product_variant.combo = !value;
    },
    addVariant() {
      this.$emit("addVariant", this.product_variant);
      this.resetVariant();
    },
    comboAdded(comboProduct) {
      this.product_variant.combo_items.push(comboProduct);
      this.comboModal = false;
    },
    openComboModal() {
      this.comboModal = true;
    },
    removeCombo(comboProduct) {
      this.product_variant.combo_items = this.product_variant.combo_items.filter(
        (combo) => comboProduct.title !== combo.title
      );
    },
    async generateBarcode() {
      this.isLoading = true;
      await this.$store.dispatch("GENERATE_BARCODE");
      const res = this.$store.getters["GET_GENERATED_BARCODE"];

      this.$store.commit("POPULATE_GENERATED_BARCODE", null);

      if (res.status) {
        this.product_variant.barcode = res.data;
      } else {
        // alert(res.message);
        eventBus.$emit('trac-alert', { message: res.message });
      }
      this.isLoading = false;
    },
    resetVariant() {
      this.product_variant = {
        title: "",
        price: "",
        barcode: "",
        description: "",
        combo: false,
        combo_items: [],
        store_stock: this.stores.map((store) => {
          return {
            re_order: 0,
            stock: 0,
            store_id: store._id,
            store_name: store.name,
          };
        }),
      };
    },
  },
};
</script>

<style scoped></style>
